import React from 'react'
import { Link } from "react-scroll";

import FacebookIcon from '../img/social/facebook'
import InstagramIcon from '../img/social/instagram'
import WhatsAppIcon from '../img/social/whatsapp'
import logo from '../img/logo_light.svg'


const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        this.state.active ? 
          this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent is-fixed"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="home" className="navbar-item" title="Logo">
              <img src={logo} alt="Betty's Restaurant" style={{ width: '120px' }} />
            </Link>
            {/* Hamburger menu */}
            <button
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              onKeyDown={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link smooth={true} spy={true} offset={-70} activeClass="active" className="navbar-item" to="home">
                <span>Benvenuto</span>
              </Link>
              
              <Link smooth={true} spy={true} offset={-70} activeClass="active" className="navbar-item" to="menu">
                <span>Menù</span>
              </Link>

              <Link smooth={true} spy={true} offset={-50} activeClass="active" className="navbar-item" to="orari">
                <span>Orari</span>
              </Link>
              
              <Link smooth={true} spy={true} offset={-50} activeClass="active" className="navbar-item" to="contatti">
                <span>Contatti</span>
              </Link>
            </div>
            <div className="navbar-end has-text-centered">
              <a
                className="navbar-item"
                href="https://www.facebook.com/bettyscastiglione/"
                target="_blank"
                rel="noopener noreferrer"
                title="facebook"
              >
                <span className="icon">
                  <FacebookIcon/>
                </span>
              </a>
              <a
                className="navbar-item"
                href="https://www.instagram.com/bettysrestaurant/"
                target="_blank"
                rel="noopener noreferrer"
                title='instagram'
              >
                <span className="icon">
                  <InstagramIcon/>
                </span>
              </a>
              <a
                className="navbar-item"
                href="https://wa.me/393383155295"
                target="_blank"
                rel="noopener noreferrer"
                title="whatsapp"
              >
                <span className="icon">
                  <WhatsAppIcon/>
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
